<div class="btn-group-vertical row" id="ProjectRow">

  <label class="projectheader" id="projectheader1" for="carouselExampleIndicators1" *ngIf="id == 1">DIEPENHEIM</label>
  <ngb-carousel id="carouselExampleIndicators1" *ngIf="id == 1">
    <ng-template ngbSlide *ngFor="let image of images">
      <div class="wrapper">
        <img [src]="image.src" alt="Random first slide">
      </div>
    </ng-template>
  </ngb-carousel>

  <label class="projectheader" *ngIf="id == 2" id="projectheader2"
         for="carouselExampleIndicators2">KONIJNENSTRAAT, AMSTERDAM
    <a href="https://www.dezeen.com/2020/10/27/the-gymnasium-apartment-robbert-de-goede-amsterdam/" target="_blank" style="font-size: 70%;">(Dezeen Awards Artikel)</a> </label>
  <ngb-carousel id="carouselExampleIndicators2" *ngIf="id == 2">
    <ng-template ngbSlide *ngFor="let image of images2">
      <div class="wrapper">
        <img [src]="image.src" alt="Random first slide">
      </div>
    </ng-template>
  </ngb-carousel>

  <!--            PROJECT 3-->

  <label class="projectheader" *ngIf="id == 3" id="projectheader3" for="carouselExampleIndicators3">CONSTANTIJN HUIJGENSTRAAT, AMSTERDAM</label>
  <ngb-carousel id="carouselExampleIndicators3" *ngIf="id == 3">
    <ng-template ngbSlide *ngFor="let image of images3">
      <div class="wrapper">
        <img [src]="image.src" alt="Random first slide">
      </div>
    </ng-template>
  </ngb-carousel>

  <!--            PROJECT 4-->

  <label class="projectheader" *ngIf="id == 4" id="projectheader4" for="carouselExampleIndicators4">FRANS VAN MIERISSTRAAT, AMSTERDAM</label>
  <ngb-carousel id="carouselExampleIndicators4" *ngIf="id == 4">
    <ng-template ngbSlide *ngFor="let image of images4">
      <div class="wrapper">
        <img [src]="image.src" alt="Random first slide">
      </div>
    </ng-template>
  </ngb-carousel>

  <!--            PROJECT 5-->

  <label class="projectheader" *ngIf="id == 5" id="projectheader5" for="carouselExampleIndicators5">BACHPLEIN, AMSTERDAM</label>
  <ngb-carousel id="carouselExampleIndicators5" *ngIf="id == 5">
    <ng-template ngbSlide *ngFor="let image of images5">
      <div class="wrapper">
        <img [src]="image.src" alt="Random first slide">
      </div>
    </ng-template>
  </ngb-carousel>

  <!--            PROJECT 6-->

  <label class="projectheader" *ngIf="id == 6" id="projectheader6" for="carouselExampleIndicators6">HERENGRACHT, AMSTERDAM</label>
  <ngb-carousel id="carouselExampleIndicators6" *ngIf="id == 6">
    <ng-template ngbSlide *ngFor="let image of images6">
      <div class="wrapper">
        <img [src]="image.src" alt="Random first slide">
      </div>
    </ng-template>
  </ngb-carousel>

  <!--            PROJECT 7-->

  <label class="projectheader" *ngIf="id == 7" id="projectheader7" for="carouselExampleIndicators7">BRONCKHORSTSTRAAT, AMSTERDAM</label>
  <ngb-carousel id="carouselExampleIndicators7" *ngIf="id == 7">
    <ng-template ngbSlide *ngFor="let image of images7">
      <div class="wrapper">
        <img [src]="image.src" alt="Random first slide">
      </div>
    </ng-template>
  </ngb-carousel>

  <!--            PROJECT 8-->

  <label class="projectheader" *ngIf="id == 8" id="projectheader8" for="carouselExampleIndicators7">MIRACLE 72</label>
  <ngb-carousel id="carouselExampleIndicators8" *ngIf="id == 8">
    <ng-template ngbSlide *ngFor="let image of images8">
      <div class="wrapper">
        <img [src]="image.src" alt="Random first slide">
      </div>
    </ng-template>
  </ngb-carousel>

  <!--            PROJECT 9-->

  <label class="projectheader" *ngIf="id == 9" id="projectheader8" for="carouselExampleIndicators7">Herengracht</label>
  <ngb-carousel id="carouselExampleIndicators8" *ngIf="id == 9">
    <ng-template ngbSlide *ngFor="let image of images9">
      <div class="wrapper">
        <img [src]="image.src" alt="Random first slide">
      </div>
    </ng-template>
  </ngb-carousel>

  <!--            PROJECT 10-->

  <label class="projectheader" *ngIf="id == 10" id="projectheader8" for="carouselExampleIndicators7">Keizersgracht</label>
  <ngb-carousel id="carouselExampleIndicators8" *ngIf="id == 10">
    <ng-template ngbSlide *ngFor="let image of images10">
      <div class="wrapper">
        <img [src]="image.src" alt="Random first slide">
      </div>
    </ng-template>
  </ngb-carousel>

  <p id="info">&#9432; Click on the foto to view fullscreen</p>

</div>
