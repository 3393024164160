import { Component, OnInit } from '@angular/core';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  providers: [NgbCarouselConfig]
})
export class HomeComponent implements OnInit {

  images = [
    {title: 'Third Slide', short: 'Third Slide Short', src: 'https://images.pexels.com/photos/1796705/pexels-photo-1796705.jpeg?cs=srgb&dl=pexels-chait-goli-1796705.jpg&fm=jpg'},
    {title: 'First Slide', short: 'First Slide Short', src: 'https://res.cloudinary.com/dsn2qui7p/image/upload/v1592671734/VKV%20Restauraties/Home/Homepic_1.jpg'},
    {title: 'Second Slide', short: 'Second Slide Short', src: 'https://images.pexels.com/photos/1329510/pexels-photo-1329510.jpeg?cs=srgb&dl=pexels-liene-ratniece-1329510.jpg&fm=jpg'}
  ];

  constructor(config: NgbCarouselConfig) {
    config.interval = 10000;
    config.keyboard = true;
    config.pauseOnHover = false;
    config.wrap = false;
  }

  ngOnInit(): void {
  }

}
