import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {HomeComponent} from './mainpage/home/home.component';
import {ContactComponent} from './mainpage/contact/contact.component';
import {MapComponent} from './mainpage/map/map.component';
import {ProjectsComponent} from './mainpage/projects/projects.component';
import {AboutComponent} from './mainpage/about/about.component';
import {ProjectsViewComponent} from './mainpage/projects-view/projects-view.component';

const routes: Routes = [
  { path: '',   redirectTo: '/home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'map', component: MapComponent },
  { path: 'projects', component: ProjectsComponent },
  { path: 'about', component: AboutComponent },
  { path: 'projectsview/:id', component: ProjectsViewComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
