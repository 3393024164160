<div id="app" class="row container-fluid">

  <header class="navbar-header" style="font-size: 115%;"><a routerLink="/home">VKV RESTAURATIES</a></header>

  <div class="navbar-items">
    <ul style="list-style-type:none">
      <li class="nav-item"><a routerLink="/projects">PROJECTS</a></li>
      <li class="nav-item"><a routerLink="/about">ABOUT</a></li>
      <li class="nav-item"><a routerLink="/contact">CONTACT</a></li>
      <li class="nav-item"><a routerLink="/map">MAP</a></li>
    </ul>
  </div>

</div>
