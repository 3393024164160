<!--<ngb-carousel>-->
<!--  <ng-template ngbSlide *ngFor="let image of images">-->
<!--    <div class="wrapper">-->
<!--      <img [src]="image.src" alt="Random first slide">-->
<!--    </div>-->
<!--  </ng-template>-->
<!--</ngb-carousel>-->
<div id="app">
  <header>
    <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
<!--      <ol class="carousel-indicators">-->
<!--        <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>-->
<!--        <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>-->
<!--      </ol>-->
      <div class="carousel-inner" role="listbox">
        <!-- Slide One - Set the background image for this slide in the line below -->
        <div class="carousel-item active" style="background-image: url('https://res.cloudinary.com/dsn2qui7p/image/upload/v1592671734/VKV%20Restauraties/Home/Homepic_1.jpg')">
          <div class="carousel-caption d-none d-md-block"></div>
        </div>
        <!-- Slide Two - Set the background image for this slide in the line below -->
        <div class="carousel-item" style="background-image: url('https://images.pexels.com/photos/851039/pexels-photo-851039.jpeg?cs=srgb&dl=photography-of-buildings-near-the-canal-851039.jpg&fm=jpg')">
          <div class="carousel-caption d-none d-md-block"></div>
        </div>
      </div>
    </div>
  </header>
</div>
