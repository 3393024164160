import { Component, OnInit } from '@angular/core';
// @ts-ignore
import locations from '../../locations.json';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css']
})
export class MapComponent implements OnInit {

  latList: number[] = [];
  lngList: number[] = [];

  constructor() {}

  ngOnInit(): void {
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < locations.markers.length; i++) {
      console.log(locations.markers[i].position.lat + ' : ' + locations.markers[i].position.lng);
      this.latList.push(locations.markers[i].position.lat);
      this.lngList.push(locations.markers[i].position.lng);
    }
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.latList.length; i++) {
      console.log(this.latList[i]);
      console.log(this.lngList[i]);
    }
  }

}
