<div class="page" id="app">

  <div class="row container-fluid">

    <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 container-item">
      <a routerLink="/projectsview/1">
        <div class="hovereffect">
          <img class="img-fluid" src="https://res.cloudinary.com/dsn2qui7p/image/upload/f_auto/v1592674840/VKV%20Restauraties/Diepenheim/IMG_2410_joq18g.jpg" alt="">
          <div class="overlay">
            <p><b>Diepenheim</b></p>
            <p class="project_text">De verbouwing van een vervallen boederij tot een riant buitenverblijf
              met diverse bijgebouwen en een zwemvijver.</p>
          </div>
        </div>
        </a>
    </div>

    <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 container-item">
      <a routerLink="/projectsview/9">
        <div class="hovereffect">
          <img class="img-responsive" src="https://res.cloudinary.com/dsn2qui7p/image/upload/v1646910011/VKV%20Restauraties/Herengracht%20237/dbe0d1cc-bd3c-4e2f-85a5-572c58c5c3b0_vwfuwl.jpg" alt="">
          <div class="overlay">
            <p><b>Herengracht</b></p>
            <p class="project_text">...</p>
          </div>
        </div>
      </a>
    </div>

    <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 container-item">
      <a routerLink="/projectsview/10">
        <div class="hovereffect">
          <img class="img-responsive" src="https://res.cloudinary.com/dsn2qui7p/image/upload/v1646911843/VKV%20Restauraties/Keizersgracht%20210/K-210-16_bbm3rl.jpg" alt="">
          <div class="overlay">
            <p><b>Keizersgracht</b></p>
            <p class="project_text">...</p>
          </div>
        </div>
      </a>
    </div>

    <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 container-item">
      <a routerLink="/projectsview/2">
        <div class="hovereffect">
          <img class="img-fluid" src="https://res.cloudinary.com/dsn2qui7p/image/upload/f_auto/v1592685389/VKV%20Restauraties/Konijnenstraat%2016/9V6A2669_k5v9lz.jpg" alt="">
          <div class="overlay">
            <p><b>Konijnenstraat</b></p>
            <p class="project_text">De verbouwing van een oude gymzaal in het centrum van Amsterdam. Winnaar van de Dezeen Award 2020, mooiste interieur ter wereld.</p>
          </div>
        </div>
      </a>
    </div>

    <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 container-item">
      <a routerLink="/projectsview/3">
        <div class="hovereffect">
          <img class="img-fluid" src="https://res.cloudinary.com/dsn2qui7p/image/upload/f_auto/v1590155527/VKV%20Restauraties/ConstantijnHuygenstraat/CHS1.jpg" alt="">
          <div class="overlay">
            <p><b>Constantijn Huygenstraat</b></p>
            <p class="project_text">Een luxieus pied à terre aan het Vondelpark.</p>
          </div>
        </div>
      </a>
    </div>

    <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 container-item">
      <a routerLink="/projectsview/4">
        <div class="hovereffect">
          <img class="img-fluid" src="https://res.cloudinary.com/dsn2qui7p/image/upload/c_scale,h_900,w_1350/v1596543701/VKV%20Restauraties/FransvanMierisstraat/FVM01.jpg" alt="">
          <div class="overlay">
            <p><b>Frans van Mierisstraat</b></p>
            <p class="project_text">De verbouwing van een driedubbel benedenhuis in Amsterdam-Zuid met nieuw toegevoegde kelder.</p>
          </div>
        </div>
      </a>
    </div>

    <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 container-item">
      <a routerLink="/projectsview/7">
        <div class="hovereffect">
          <img class="img-responsive" src="https://res.cloudinary.com/dsn2qui7p/image/upload/v1605202651/VKV%20Restauraties/Bronckhorststraat/Bronckhorststraat_16-2_-_29_vepppy.jpg" alt="">
          <div class="overlay">
            <p><b>Bronckhorststraat</b></p>
            <p class="project_text">Appartement op de derde verdieping werd een dubbel bovenhuis met twee dakterrassen.</p>
          </div>
        </div>
      </a>
    </div>

    <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 container-item">
      <a routerLink="/projectsview/6">
        <div class="hovereffect">
          <img class="img-responsive" src="https://res.cloudinary.com/dsn2qui7p/image/upload/c_scale,h_900,w_1350/v1605200495/VKV%20Restauraties/Herengracht%20555/12_harmonie_leeg_epvarc.jpg" alt="">
          <div class="overlay">
            <p><b>Herengracht</b></p>
            <p class="project_text">De restauratie van een rijksmonument nu in gebruik als uitgeverij.</p>
          </div>
        </div>
      </a>
    </div>

    <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 container-item">
      <a routerLink="/projectsview/5">
        <div class="hovereffect">
          <img class="img-fluid" src="https://res.cloudinary.com/dsn2qui7p/image/upload/c_scale,h_900,w_1350/v1605196989/VKV%20Restauraties/Bachplein/Afb0044_vitjzo.jpg" alt="">
          <div class="overlay row2">
            <p><b>Bachplein</b></p>
            <p class="project_text">De verbouwing van een statig herenhuis in Amsterdam-Zuid.</p>
          </div>
        </div>
      </a>
    </div>

    <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 container-item">
      <a routerLink="/projectsview/8">
        <div class="hovereffect">
          <img class="img-responsive" src="https://res.cloudinary.com/dsn2qui7p/image/upload/v1605206023/VKV%20Restauraties/BONUS:%20Kerkstraat/Miracle_72-71_copy_ad3gtg.jpg" alt="">
          <div class="overlay">
            <p><b>Miracle 72</b></p>
            <p class="project_text">Als dank voor de restauratie van hun monumentale stadsvilla maakten de opdrachtgevers dit fotoboek.</p>
          </div>
        </div>
      </a>
    </div>

  </div>
</div>
