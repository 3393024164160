import { Component, OnInit } from '@angular/core';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import {ActivatedRoute, Router, ParamMap} from '@angular/router';

@Component({
  selector: 'app-projects-view',
  templateUrl: './projects-view.component.html',
  styleUrls: ['./projects-view.component.css'],
  providers: [NgbCarouselConfig]
})
export class ProjectsViewComponent implements OnInit {

  images = [

    {title: 'Diepenheim', src: 'https://res.cloudinary.com/dsn2qui7p/image/upload/c_scale,q_auto:eco,w_1500/v1592675082/VKV%20Restauraties/Diepenheim/IMG_2344_ufupev.jpg'},
    {title: 'Diepenheim', src: 'https://res.cloudinary.com/dsn2qui7p/image/upload/c_scale,q_auto:eco,w_1500/v1592674810/VKV%20Restauraties/Diepenheim/IMG_2422_cmnlig.jpg'},
    {title: 'Diepenheim', src: 'https://res.cloudinary.com/dsn2qui7p/image/upload/c_scale,q_auto:eco,w_1500/v1592674805/VKV%20Restauraties/Diepenheim/IMG_1575_afiund.jpg'},
    {title: 'Diepenheim', src: 'https://res.cloudinary.com/dsn2qui7p/image/upload/c_scale,q_auto:eco,w_1500/v1592674840/VKV%20Restauraties/Diepenheim/IMG_2410_joq18g.jpg'},
    {title: 'Diepenheim', src: 'https://res.cloudinary.com/dsn2qui7p/image/upload/c_scale,q_auto:eco,w_1500/v1592674808/VKV%20Restauraties/Diepenheim/IMG_2325_hvsiwa.jpg'},
    {title: 'Diepenheim', src: 'https://res.cloudinary.com/dsn2qui7p/image/upload/c_scale,q_auto:eco,w_1500/v1592674842/VKV%20Restauraties/Diepenheim/IMG_2571_tedszx.jpg'},
    {title: 'Diepenheim', src: 'https://res.cloudinary.com/dsn2qui7p/image/upload/c_scale,q_auto:eco,w_1500/v1592674834/VKV%20Restauraties/Diepenheim/IMG_1595_pv3evj.jpg'},
    {title: 'Diepenheim', src: 'https://res.cloudinary.com/dsn2qui7p/image/upload/c_scale,q_auto:eco,w_1500/v1592674812/VKV%20Restauraties/Diepenheim/IMG_2412_uwu99e.jpg'},

  ];

  images2 = [

    {title: 'Konijnenstraat', src: 'https://res.cloudinary.com/dsn2qui7p/image/upload/v1592685389/VKV%20Restauraties/Konijnenstraat%2016/9V6A2669_k5v9lz.jpg'},
    {title: 'Konijnenstraat', src: 'https://res.cloudinary.com/dsn2qui7p/image/upload/v1592685389/VKV%20Restauraties/Konijnenstraat%2016/9V6A2951_sojgzb.jpg'},
    {title: 'Konijnenstraat', src: 'https://res.cloudinary.com/dsn2qui7p/image/upload/v1592685389/VKV%20Restauraties/Konijnenstraat%2016/9V6A2885_ud6akk.jpg'},
    {title: 'Konijnenstraat', src: 'https://res.cloudinary.com/dsn2qui7p/image/upload/v1592685389/VKV%20Restauraties/Konijnenstraat%2016/9V6A2722_asigeq.jpg'},
    {title: 'Konijnenstraat', src: 'https://res.cloudinary.com/dsn2qui7p/image/upload/v1605196572/VKV%20Restauraties/Konijnenstraat%2016/kamer.jpg'},
    {title: 'Konijnenstraat', src: 'https://res.cloudinary.com/dsn2qui7p/image/upload/c_scale,w_734/v1605196562/VKV%20Restauraties/Konijnenstraat%2016/trap.jpg'},
    {title: 'Konijnenstraat', src: 'https://res.cloudinary.com/dsn2qui7p/image/upload/v1605199305/VKV%20Restauraties/Konijnenstraat%2016/9V6A2648_matmpp.jpg'},
    {title: 'Konijnenstraat', src: 'https://res.cloudinary.com/dsn2qui7p/image/upload/v1605199305/VKV%20Restauraties/Konijnenstraat%2016/9V6A2661_gezf5i.jpg'},

    {title: 'Konijnenstraat', src: 'https://res.cloudinary.com/dsn2qui7p/image/upload/v1605199305/VKV%20Restauraties/Konijnenstraat%2016/9V6A2603_nktxzo.jpg'},
    {title: 'Konijnenstraat', src: 'https://res.cloudinary.com/dsn2qui7p/image/upload/v1605199305/VKV%20Restauraties/Konijnenstraat%2016/9V6A2659_jyxk4v.jpg'},
    {title: 'Konijnenstraat', src: 'https://res.cloudinary.com/dsn2qui7p/image/upload/v1605199306/VKV%20Restauraties/Konijnenstraat%2016/9V6A2696_blyo9h.jpg'},
    {title: 'Konijnenstraat', src: 'https://res.cloudinary.com/dsn2qui7p/image/upload/v1605199306/VKV%20Restauraties/Konijnenstraat%2016/9V6A2620_iboszk.jpg'},
    {title: 'Konijnenstraat', src: 'https://res.cloudinary.com/dsn2qui7p/image/upload/v1605199307/VKV%20Restauraties/Konijnenstraat%2016/9V6A2736_peqlvv.jpg'},
    {title: 'Konijnenstraat', src: 'https://res.cloudinary.com/dsn2qui7p/image/upload/v1605199307/VKV%20Restauraties/Konijnenstraat%2016/9V6A2714_ksvc8x.jpg'},
    {title: 'Konijnenstraat', src: 'https://res.cloudinary.com/dsn2qui7p/image/upload/v1605199307/VKV%20Restauraties/Konijnenstraat%2016/9V6A2754_m4pwel.jpg'},
    {title: 'Konijnenstraat', src: 'https://res.cloudinary.com/dsn2qui7p/image/upload/v1605199308/VKV%20Restauraties/Konijnenstraat%2016/9V6A2748_s3aiab.jpg'},
    {title: 'Konijnenstraat', src: 'https://res.cloudinary.com/dsn2qui7p/image/upload/v1605199308/VKV%20Restauraties/Konijnenstraat%2016/9V6A2775_voygx8.jpg'},
    {title: 'Konijnenstraat', src: 'https://res.cloudinary.com/dsn2qui7p/image/upload/v1605199308/VKV%20Restauraties/Konijnenstraat%2016/9V6A2814_g8vuhj.jpg'},
    {title: 'Konijnenstraat', src: 'https://res.cloudinary.com/dsn2qui7p/image/upload/v1605199309/VKV%20Restauraties/Konijnenstraat%2016/9V6A2852_rtwqxx.jpg'},
    {title: 'Konijnenstraat', src: 'https://res.cloudinary.com/dsn2qui7p/image/upload/v1605199309/VKV%20Restauraties/Konijnenstraat%2016/9V6A2824_ip7hl2.jpg'},
    {title: 'Konijnenstraat', src: 'https://res.cloudinary.com/dsn2qui7p/image/upload/v1605199310/VKV%20Restauraties/Konijnenstraat%2016/9V6A2880_tgagu3.jpg'},
    {title: 'Konijnenstraat', src: 'https://res.cloudinary.com/dsn2qui7p/image/upload/v1605199309/VKV%20Restauraties/Konijnenstraat%2016/9V6A2860_khqs6t.jpg'},
    {title: 'Konijnenstraat', src: 'https://res.cloudinary.com/dsn2qui7p/image/upload/v1605199309/VKV%20Restauraties/Konijnenstraat%2016/9V6A2873_l6iend.jpg'},
    {title: 'Konijnenstraat', src: 'https://res.cloudinary.com/dsn2qui7p/image/upload/v1605199310/VKV%20Restauraties/Konijnenstraat%2016/9V6A2893_wrjlmm.jpg'},
    {title: 'Konijnenstraat', src: 'https://res.cloudinary.com/dsn2qui7p/image/upload/v1605199310/VKV%20Restauraties/Konijnenstraat%2016/9V6A2899_pvlhdx.jpg'},
    {title: 'Konijnenstraat', src: 'https://res.cloudinary.com/dsn2qui7p/image/upload/v1605199311/VKV%20Restauraties/Konijnenstraat%2016/9V6A2918_nlxpmm.jpg'},
    {title: 'Konijnenstraat', src: 'https://res.cloudinary.com/dsn2qui7p/image/upload/v1605199311/VKV%20Restauraties/Konijnenstraat%2016/9V6A2940_h6tizf.jpg'},
    {title: 'Konijnenstraat', src: 'https://res.cloudinary.com/dsn2qui7p/image/upload/v1605199310/VKV%20Restauraties/Konijnenstraat%2016/9V6A2913_krddcg.jpg'},
    {title: 'Konijnenstraat', src: 'https://res.cloudinary.com/dsn2qui7p/image/upload/v1605199311/VKV%20Restauraties/Konijnenstraat%2016/9V6A2925_e4p0mx.jpg'},
    {title: 'Konijnenstraat', src: 'https://res.cloudinary.com/dsn2qui7p/image/upload/v1605199312/VKV%20Restauraties/Konijnenstraat%2016/9V6A2977_rtlebh.jpg'},
    {title: 'Konijnenstraat', src: 'https://res.cloudinary.com/dsn2qui7p/image/upload/v1605199313/VKV%20Restauraties/Konijnenstraat%2016/9V6A2947_yjdm0p.jpg'},
    {title: 'Konijnenstraat', src: 'https://res.cloudinary.com/dsn2qui7p/image/upload/v1605199313/VKV%20Restauraties/Konijnenstraat%2016/9V6A2983_gnqqcj.jpg'},
    {title: 'Konijnenstraat', src: 'https://res.cloudinary.com/dsn2qui7p/image/upload/v1605199321/VKV%20Restauraties/Konijnenstraat%2016/IMG_1626_gje7g1.jpg'},
    {title: 'Konijnenstraat', src: 'https://res.cloudinary.com/dsn2qui7p/image/upload/v1605199315/VKV%20Restauraties/Konijnenstraat%2016/IMG_7248_jwu502.jpg'},

  ];

  images3 = [

    {title: 'Constantijn Huygenstraat', src: 'https://res.cloudinary.com/dsn2qui7p/image/upload/v1590155527/VKV%20Restauraties/ConstantijnHuygenstraat/CHS1.jpg'},
    {title: 'Constantijn Huygenstraat', src: 'https://res.cloudinary.com/dsn2qui7p/image/upload/v1590156094/VKV%20Restauraties/ConstantijnHuygenstraat/CHS2.jpg'},
    {title: 'Constantijn Huygenstraat', src: 'https://res.cloudinary.com/dsn2qui7p/image/upload/v1590156125/VKV%20Restauraties/ConstantijnHuygenstraat/CHS3.jpg'},
    {title: 'Constantijn Huygenstraat', src: 'https://res.cloudinary.com/dsn2qui7p/image/upload/v1592664606/VKV%20Restauraties/ConstantijnHuygenstraat/CHS4.jpg'},
    {title: 'Constantijn Huygenstraat', src: 'https://res.cloudinary.com/dsn2qui7p/image/upload/v1590156168/VKV%20Restauraties/ConstantijnHuygenstraat/CHS5.jpg'},
    {title: 'Constantijn Huygenstraat', src: 'https://res.cloudinary.com/dsn2qui7p/image/upload/v1592664812/VKV%20Restauraties/ConstantijnHuygenstraat/CHS6.jpg'}

  ];

  images4 = [

    {title: 'Frans van Mierisstraat', src: 'https://res.cloudinary.com/dsn2qui7p/image/upload/v1596625294/VKV%20Restauraties/FransvanMierisstraat/IMG_0852_casceg.jpg'},
    {title: 'Frans van Mierisstraat', src: 'https://res.cloudinary.com/dsn2qui7p/image/upload/v1596625221/VKV%20Restauraties/FransvanMierisstraat/IMG_0841_vdklsj.jpg'},
    {title: 'Frans van Mierisstraat', src: 'https://res.cloudinary.com/dsn2qui7p/image/upload/v1596543701/VKV%20Restauraties/FransvanMierisstraat/FVM01.jpg'},

  ];

  images5 = [

    {title: 'Bachplein', src: 'https://res.cloudinary.com/dsn2qui7p/image/upload/c_scale,w_703/v1605196989/VKV%20Restauraties/Bachplein/Afb0040_qh0h8x.jpg'},
    {title: 'Bachplein', src: 'https://res.cloudinary.com/dsn2qui7p/image/upload/c_scale,w_703/v1605196989/VKV%20Restauraties/Bachplein/Afb0042_biwpow.jpg'},
    {title: 'Bachplein', src: 'https://res.cloudinary.com/dsn2qui7p/image/upload/c_scale,w_703/v1605196989/VKV%20Restauraties/Bachplein/Afb0044_vitjzo.jpg'},
    {title: 'Bachplein', src: 'https://res.cloudinary.com/dsn2qui7p/image/upload/c_scale,w_703/v1605196989/VKV%20Restauraties/Bachplein/Afb0041_b70uzd.jpg'},
    {title: 'Bachplein', src: 'https://res.cloudinary.com/dsn2qui7p/image/upload/c_scale,w_703/v1605198186/VKV%20Restauraties/Bachplein/Afb0060_mgqiyf.jpg'},
    {title: 'Bachplein', src: 'https://res.cloudinary.com/dsn2qui7p/image/upload/c_scale,w_703/v1605196989/VKV%20Restauraties/Bachplein/Afb0039_jfgytw.jpg'},

  ];

  images6 = [

    {title: 'Herengracht', src: 'https://res.cloudinary.com/dsn2qui7p/image/upload/c_scale,w_851/v1605201911/VKV%20Restauraties/Herengracht%20555/11_harmonie_leeg_phuadf.jpg'},
    {title: 'Herengracht', src: 'https://res.cloudinary.com/dsn2qui7p/image/upload/v1605200494/VKV%20Restauraties/Herengracht%20555/05_harmonie_leeg_wbka5m.jpg'},
    {title: 'Herengracht', src: 'https://res.cloudinary.com/dsn2qui7p/image/upload/v1605200494/VKV%20Restauraties/Herengracht%20555/06_harmonie_leeg_n24t08.jpg'},
    {title: 'Herengracht', src: 'https://res.cloudinary.com/dsn2qui7p/image/upload/v1605200495/VKV%20Restauraties/Herengracht%20555/12_harmonie_leeg_epvarc.jpg'},
    {title: 'Herengracht', src: 'https://res.cloudinary.com/dsn2qui7p/image/upload/v1605200496/VKV%20Restauraties/Herengracht%20555/10_harmonie_leeg_kfghnf.jpg'},
    {title: 'Herengracht', src: 'https://res.cloudinary.com/dsn2qui7p/image/upload/v1605200497/VKV%20Restauraties/Herengracht%20555/18_harmonie_leeg_xzuqbs.jpg'},
    {title: 'Herengracht', src: 'https://res.cloudinary.com/dsn2qui7p/image/upload/v1605200498/VKV%20Restauraties/Herengracht%20555/26_harmonie_leeg_p9lhw8.jpg'},
    {title: 'Herengracht', src: 'https://res.cloudinary.com/dsn2qui7p/image/upload/v1605200498/VKV%20Restauraties/Herengracht%20555/19_harmonie_leeg_giqrpp.jpg'},
    {title: 'Herengracht', src: 'https://res.cloudinary.com/dsn2qui7p/image/upload/v1605200499/VKV%20Restauraties/Herengracht%20555/23_harmonie_leeg_pgexuk.jpg'},
    {title: 'Herengracht', src: 'https://res.cloudinary.com/dsn2qui7p/image/upload/v1605200500/VKV%20Restauraties/Herengracht%20555/29032006_001_mjfgyu.jpg'},
    {title: 'Herengracht', src: 'https://res.cloudinary.com/dsn2qui7p/image/upload/v1605200500/VKV%20Restauraties/Herengracht%20555/27_harmonie_leeg_vvyfyi.jpg'},
    {title: 'Herengracht', src: 'https://res.cloudinary.com/dsn2qui7p/image/upload/v1605200500/VKV%20Restauraties/Herengracht%20555/13_harmonie_leeg_ogyqqz.jpg'},
    {title: 'Herengracht', src: 'https://res.cloudinary.com/dsn2qui7p/image/upload/v1605200500/VKV%20Restauraties/Herengracht%20555/31_harmonie_leeg_ubuhlf.jpg'},
    {title: 'Herengracht', src: 'https://res.cloudinary.com/dsn2qui7p/image/upload/v1605200500/VKV%20Restauraties/Herengracht%20555/30_harmonie_leeg_jhyjaq.jpg'},
    {title: 'Herengracht', src: 'https://res.cloudinary.com/dsn2qui7p/image/upload/v1605200503/VKV%20Restauraties/Herengracht%20555/IMG_1138_xlxvvv.jpg'},
    {title: 'Herengracht', src: 'https://res.cloudinary.com/dsn2qui7p/image/upload/v1605200505/VKV%20Restauraties/Herengracht%20555/IMG_1732_frckng.jpg'},
    {title: 'Herengracht', src: 'https://res.cloudinary.com/dsn2qui7p/image/upload/v1605200505/VKV%20Restauraties/Herengracht%20555/IMG_1754_hnoo9c.jpg'},

  ];

  images7 = [

    {title: 'Bronckhorststraat', src: 'https://res.cloudinary.com/dsn2qui7p/image/upload/v1605202651/VKV%20Restauraties/Bronckhorststraat/Bronckhorststraat_16-2_-_29_vepppy.jpg'},
    {title: 'Bronckhorststraat', src: 'https://res.cloudinary.com/dsn2qui7p/image/upload/v1605202646/VKV%20Restauraties/Bronckhorststraat/Bronckhorststraat_16-2_-_17_rma0dn.jpg'},
    {title: 'Bronckhorststraat', src: 'https://res.cloudinary.com/dsn2qui7p/image/upload/v1605202649/VKV%20Restauraties/Bronckhorststraat/Bronckhorststraat_16-2_-_19_ladore.jpg'},
    {title: 'Bronckhorststraat', src: 'https://res.cloudinary.com/dsn2qui7p/image/upload/v1605202645/VKV%20Restauraties/Bronckhorststraat/Bronckhorststraat_16-2_-_12_rrkez4.jpg'},
    {title: 'Bronckhorststraat', src: 'https://res.cloudinary.com/dsn2qui7p/image/upload/v1605202653/VKV%20Restauraties/Bronckhorststraat/Bronckhorststraat_16-2_-_27_mpk82w.jpg'},
    {title: 'Bronckhorststraat', src: 'https://res.cloudinary.com/dsn2qui7p/image/upload/v1605202652/VKV%20Restauraties/Bronckhorststraat/Bronckhorststraat_16-2_-_30_a84he5.jpg'},
    {title: 'Bronckhorststraat', src: 'https://res.cloudinary.com/dsn2qui7p/image/upload/v1605202648/VKV%20Restauraties/Bronckhorststraat/Bronckhorststraat_16-2_-_25_yixvgy.jpg'},
    {title: 'Bronckhorststraat', src: 'https://res.cloudinary.com/dsn2qui7p/image/upload/v1605202649/VKV%20Restauraties/Bronckhorststraat/Bronckhorststraat_16-2_-_28_shzxzk.jpg'},
    {title: 'Bronckhorststraat', src: 'https://res.cloudinary.com/dsn2qui7p/image/upload/v1605202646/VKV%20Restauraties/Bronckhorststraat/Bronckhorststraat_16-2_-_15_ne86pr.jpg'},
    {title: 'Bronckhorststraat', src: 'https://res.cloudinary.com/dsn2qui7p/image/upload/v1605202645/VKV%20Restauraties/Bronckhorststraat/Bronckhorststraat_16-2_-_13_olguoj.jpg'},
    {title: 'Bronckhorststraat', src: 'https://res.cloudinary.com/dsn2qui7p/image/upload/v1605202646/VKV%20Restauraties/Bronckhorststraat/Bronckhorststraat_16-2_-_14_rf0qac.jpg'},

  ];

  images8 = [

    {title: 'miracle72', src: '../assets/miracle72/p1.png'},
    {title: 'miracle72', src: '../assets/miracle72/p2.png'},
    {title: 'miracle72', src: '../assets/miracle72/p3.png'},
    {title: 'miracle72', src: '../assets/miracle72/p4.png'},
    {title: 'miracle72', src: '../assets/miracle72/p5.png'},
    {title: 'miracle72', src: '../assets/miracle72/p6.png'},
    {title: 'miracle72', src: '../assets/miracle72/p7.png'},
    {title: 'miracle72', src: '../assets/miracle72/p8.png'},
    {title: 'miracle72', src: '../assets/miracle72/p9.png'},
    {title: 'miracle72', src: '../assets/miracle72/p10.png'},
    {title: 'miracle72', src: '../assets/miracle72/p11.png'},
    {title: 'miracle72', src: '../assets/miracle72/p12.png'},
    {title: 'miracle72', src: '../assets/miracle72/p12.png'},
    {title: 'miracle72', src: '../assets/miracle72/p13.png'},
    {title: 'miracle72', src: '../assets/miracle72/p14.png'},
    {title: 'miracle72', src: '../assets/miracle72/p15.png'},
    {title: 'miracle72', src: '../assets/miracle72/p16.png'},
    {title: 'miracle72', src: '../assets/miracle72/p17.png'},
    {title: 'miracle72', src: '../assets/miracle72/p18.png'},
    {title: 'miracle72', src: '../assets/miracle72/p19.png'},
    {title: 'miracle72', src: '../assets/miracle72/p20.png'},
    {title: 'miracle72', src: '../assets/miracle72/p21.png'},
    {title: 'miracle72', src: '../assets/miracle72/p22.png'},
    {title: 'miracle72', src: '../assets/miracle72/p23.png'},
    {title: 'miracle72', src: '../assets/miracle72/p24.png'},
    {title: 'miracle72', src: '../assets/miracle72/p25.png'},
    {title: 'miracle72', src: '../assets/miracle72/p26.png'},
    {title: 'miracle72', src: '../assets/miracle72/p27.png'},
    {title: 'miracle72', src: '../assets/miracle72/p28.png'},
    {title: 'miracle72', src: '../assets/miracle72/p29.png'},
    {title: 'miracle72', src: '../assets/miracle72/p30.png'},
    {title: 'miracle72', src: '../assets/miracle72/p31.png'},
    {title: 'miracle72', src: '../assets/miracle72/p32.png'},
    {title: 'miracle72', src: '../assets/miracle72/p33.png'},
    {title: 'miracle72', src: '../assets/miracle72/p34.png'},
    {title: 'miracle72', src: '../assets/miracle72/p35.png'},
    {title: 'miracle72', src: '../assets/miracle72/p36.png'},
    {title: 'miracle72', src: '../assets/miracle72/p37.png'},
    {title: 'miracle72', src: '../assets/miracle72/p38.png'},
    {title: 'miracle72', src: '../assets/miracle72/p39.png'},
    {title: 'miracle72', src: '../assets/miracle72/p40.png'},
    {title: 'miracle72', src: '../assets/miracle72/p41.png'},
    {title: 'miracle72', src: '../assets/miracle72/p42.png'},
    {title: 'miracle72', src: '../assets/miracle72/p43.png'},
    {title: 'miracle72', src: '../assets/miracle72/p44.png'},
    {title: 'miracle72', src: '../assets/miracle72/p45.png'},
    {title: 'miracle72', src: '../assets/miracle72/p46.png'},
    {title: 'miracle72', src: '../assets/miracle72/p47.png'},
    {title: 'miracle72', src: '../assets/miracle72/p48.png'},
    {title: 'miracle72', src: '../assets/miracle72/p49.png'},
    {title: 'miracle72', src: '../assets/miracle72/p50.png'},
    {title: 'miracle72', src: '../assets/miracle72/p51.png'},
    {title: 'miracle72', src: '../assets/miracle72/p52.png'},
    {title: 'miracle72', src: '../assets/miracle72/p53.png'},
    {title: 'miracle72', src: '../assets/miracle72/p54.png'},
    {title: 'miracle72', src: '../assets/miracle72/p55.png'},
    {title: 'miracle72', src: '../assets/miracle72/p56.png'},
    {title: 'miracle72', src: '../assets/miracle72/p57.png'},
    {title: 'miracle72', src: '../assets/miracle72/p58.png'},
    {title: 'miracle72', src: '../assets/miracle72/p59.png'},
    {title: 'miracle72', src: '../assets/miracle72/p60.png'},
    {title: 'miracle72', src: '../assets/miracle72/p61.png'},
    {title: 'miracle72', src: '../assets/miracle72/p62.png'},
    {title: 'miracle72', src: '../assets/miracle72/p63.png'},
    {title: 'miracle72', src: '../assets/miracle72/p64.png'},
    {title: 'miracle72', src: '../assets/miracle72/p65.png'},
    {title: 'miracle72', src: '../assets/miracle72/p66.png'},
    {title: 'miracle72', src: '../assets/miracle72/p67.png'},
    {title: 'miracle72', src: '../assets/miracle72/p68.png'},
    {title: 'miracle72', src: '../assets/miracle72/p69.png'}

  ];

  images10 = [

    {title: 'Keizersgracht210', src: 'https://res.cloudinary.com/dsn2qui7p/image/upload/v1646911717/VKV%20Restauraties/Keizersgracht%20210/K-210-19_gp6h3t.jpg'},
    {title: 'Keizersgracht210', src: 'https://res.cloudinary.com/dsn2qui7p/image/upload/v1646911661/VKV%20Restauraties/Keizersgracht%20210/K-210-01_f9is9y.jpg'},
    {title: 'Keizersgracht210', src: 'https://res.cloudinary.com/dsn2qui7p/image/upload/v1646911721/VKV%20Restauraties/Keizersgracht%20210/K-210-18_kqvyky.jpg'},
    {title: 'Keizersgracht210', src: 'https://res.cloudinary.com/dsn2qui7p/image/upload/v1646911755/VKV%20Restauraties/Keizersgracht%20210/K-210-11_c1hfol.jpg'},
    {title: 'Keizersgracht210', src: 'https://res.cloudinary.com/dsn2qui7p/image/upload/v1646911787/VKV%20Restauraties/Keizersgracht%20210/K-210-03_uvan2h.jpg'},

  ];

  images9 = [

    {title: 'Herengracht237', src: 'https://res.cloudinary.com/dsn2qui7p/image/upload/v1646910009/VKV%20Restauraties/Herengracht%20237/955b9d67-681a-4cc5-8165-0f7225f2e60a_rdae72.jpg'},
    {title: 'Herengracht237', src: 'https://res.cloudinary.com/dsn2qui7p/image/upload/v1646910011/VKV%20Restauraties/Herengracht%20237/f10ea138-889c-4b18-9cad-b3392372cad6_e0ycln.jpg'},
    {title: 'Herengracht237', src: 'https://res.cloudinary.com/dsn2qui7p/image/upload/v1646910187/VKV%20Restauraties/Herengracht%20237/H-237-18_lwqsnj.jpg'},
    {title: 'Herengracht237', src: 'https://res.cloudinary.com/dsn2qui7p/image/upload/v1646910166/VKV%20Restauraties/Herengracht%20237/H-237-23_ghc6wj.jpg'},
    {title: 'Herengracht237', src: 'https://res.cloudinary.com/dsn2qui7p/image/upload/v1646910181/VKV%20Restauraties/Herengracht%20237/H-237-08_ofkqq5.jpg'},
    {title: 'Herengracht237', src: 'https://res.cloudinary.com/dsn2qui7p/image/upload/v1646910011/VKV%20Restauraties/Herengracht%20237/faf05512-f3ec-4d4a-b0a8-8489607dc918_i8okvt.jpg'},
    {title: 'Herengracht237', src: 'https://res.cloudinary.com/dsn2qui7p/image/upload/v1646910011/VKV%20Restauraties/Herengracht%20237/dbe0d1cc-bd3c-4e2f-85a5-572c58c5c3b0_vwfuwl.jpg'},

  ];

  id = 1;

  constructor(config: NgbCarouselConfig, private route: ActivatedRoute) {
    this.route.params.subscribe( params => { this.id = params.id; });

    config.interval = 10000;
    config.keyboard = true;
    config.pauseOnHover = false;
  }

  ngOnInit(): void {
  }

}
