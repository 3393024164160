import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './mainpage/navbar/navbar.component';
import { HomeComponent } from './mainpage/home/home.component';
import { ProjectsComponent } from './mainpage/projects/projects.component';
import { AboutComponent } from './mainpage/about/about.component';
import { ContactComponent } from './mainpage/contact/contact.component';
import { MapComponent } from './mainpage/map/map.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ProjectsViewComponent } from './mainpage/projects-view/projects-view.component';
import { AgmCoreModule } from '@agm/core';
import { HttpClientModule } from '@angular/common/http';

const firebaseConfig = {
  apiKey: 'AIzaSyAGLeyr1Vq9_hbeEWcQbOCCEf1C8GY1kek',
  authDomain: 'vkv-restauraties.firebaseapp.com',
  databaseURL: 'https://vkv-restauraties.firebaseio.com',
  projectId: 'vkv-restauraties',
  storageBucket: 'vkv-restauraties.appspot.com',
  messagingSenderId: '1042866475207',
  appId: '1:1042866475207:web:3c7e2442f4798abf9d6ee2',
  measurementId: 'G-6EEK3YB6NP'
};

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    HomeComponent,
    ProjectsComponent,
    AboutComponent,
    ContactComponent,
    MapComponent,
    ProjectsViewComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgbModule,
    HttpClientModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAGLeyr1Vq9_hbeEWcQbOCCEf1C8GY1kek'
    })
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
