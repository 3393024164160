<div id="app">
  <div id="wrapper">
    <div id="abouttext">
      <h1 class="entry-title">Wat wij doen</h1>
      <div class="entry-content">
        <p><strong>Verbouwingen en restauraties</strong></p>
        <p>VKV Restauraties is een allround bouwbedrijf wat alle facetten van een verbouwings- of restauratieproject kan doorlopen. Wij werken met een vast team van professionele- en enthousiaste vakmensen.<br>
          Onze werkzaamheden varieren van de restauratie van een rijksmonument inclusief funderingsherstel tot het (ver)bouwen van een eenvoudige badkamer.</p>
        <p><strong>Design en ontwerp</strong></p>
        <p>Een goed ontwerp is van het grootste belang. Wij maken daarvoor: bouwkundige tekeningen en 3D-animaties, coördineren de aanvraag van vergunningen en adviseren u in productkeuzes.</p>
        <p><strong>Duurzaam bouwen</strong></p>
        <p>Duurzaam bouwen betekent dat woningen, gebouwen en andere bouwwerken ontwikkeld en gebruikt worden met respect voor mens en milieu. Het gaat bij duurzaam bouwen niet alleen om energiebesparing in huizen en gebouwen, maar bijvoorbeeld ook om:<br>
          – gebruik van duurzame materialen die rekening houden met het milieu en de gezondheid van&nbsp; bewoners en gebruikers;
          – een gezond binnenmilieu bijvoorbeeld door goede ventilatie om vocht, schimmel en ophoping van schadelijke stoffen te voorkomen; prettige en leefbare huizen, gebouwen, wijken en steden;<br>
          – duurzaam slopen, om de materialen die vrijkomen bij de sloop opnieuw te kunnen gebruiken (hergebruik en recycling);<br>
          – verantwoord watergebruik;<br>
          – voorkomen dat grondstoffen voor bouwmaterialen uitgeput raken.
        </p>
        <p><strong>Onderhoud en beheer</strong></p>
        <p>Bij onze afdeling VKV Onderhoud en Beheer kunt u terecht voor het onderhoud en beheer van al uw vastgoed. Een gedegen onderhoud is van het grootste belang voor uw bezit. Enkele keren per jaar controleren en inventariseren wij de staat van onderhoud van het object, voeren eventueel kleine reparaties uit en zorgen dat de kosten van het onderhoud beheersbaar en (middels het opstellen van meerjaren-onderhoudsplanningen) inzichtelijk blijven. Naast het technisch beheer doen wij desgewenst ook administratief beheer en beheer voor Verenigingen van Eigenaren.</p>
      </div>
    </div>
    <div id="abouttext3">
      <div class="entry-content">
        <p><strong>Mediation voor de bouw</strong></p>
        <p>Mocht u in conflict dreigen te raken of geraakt zijn met uw aannemer dan helpen wij u graag met de oplossing van het conflict. Onze geschoolde juristen beschikken naast hun theoretische kennis over een uitgebreide praktijkervaring.</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
      </div>
    </div>
  </div>
</div>
