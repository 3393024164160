<div id="page" class="page">
  <div class="container-fluid contactform row">
    <div class="contactinfo">
      <h6>VKV RESTAURATIES</h6>
      <p>OTTO VAN KETWICH VERSCHUUR<br>
        MOBIEL: <a href="tel:+31652418553">+31 6 52418553</a><br>
        EMAIL: <a href="mailto:info@vkvrestauraties.nl">info@vkvrestauraties.nl</a><br>
        <br>
        <b>KANTOOR</b><br>van Ostadestraat 176<br>1072 TH, Amsterdam<br>Nederland<br>
        <br>
        <b>WERKPLAATS</b><br>van Ostadestraat 188<br>1072 TH, Amsterdam<br>Nederland
      </p>
    </div>
  </div>
</div>
